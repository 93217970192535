import { IoArrowBack } from "react-icons/io5";
import React, { useState } from "react";
import motoPng from "../../images/company1.png";
import motoIcon from "../../images/busIcon.png";
import { LuUser2 } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { makePaymentTraveller } from "../../../../actions/authActions";
import { useNavigate } from "react-router-dom";

const PaymentPage = () => {
  const [bookingPayment, setBookingPayment] = useState(false);
  const ticket = useSelector((state) => state.auth?.bookedTicket?.ticket || {});
  const ticketID = useSelector((state) => state.auth?.bookedTicket?.ticket?._id || '');
  const token = useSelector((state) => state.auth?.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    bookingId: ticketID,
    amount: ticket?.amount || 0,
    email: "",
    payment_type: "",
  });

  const handleBack = () => {
    navigate(-1);
  };

  const calculateTravelTime = (fromTime, toTime) => {
    if (!fromTime || !toTime) return "N/A";

    const [fromHours, fromMinutes] = fromTime.split(":").map(Number);
    const [toHours, toMinutes] = toTime.split(":").map(Number);

    const fromTotalMinutes = fromHours * 60 + fromMinutes;
    const toTotalMinutes = toHours * 60 + toMinutes;

    let durationMinutes = toTotalMinutes - fromTotalMinutes;
    if (durationMinutes < 0) durationMinutes += 24 * 60; // Handle overnight trips

    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;

    return `${hours} hrs ${minutes} mins`;
  };

  const validateFormdata = () => {
    if (!formData.email) {
      Swal.fire("Error", "Please enter your email address", "error");
      return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      Swal.fire("Error", "Enter a valid email", "error");
      return false;
    }
    if (!formData.payment_type) {
      Swal.fire("Error", "Please select a payment type to continue", "error");
      return false;
    }
    return true;
  };

  const handlePaymentTraveller = () => {
    if (validateFormdata()) {
      dispatch(makePaymentTraveller(formData, token))
        .then((response) => {
          if (response?.status === "success") {
            Swal.fire("Success", "Payment was successful!", "success");
          } else {
            Swal.fire("Error", response?.message || "Payment failed.", "error");
          }
        })
        .catch((error) => {
          Swal.fire("Error", error.message || "An unexpected error occurred.", "error");
        });
    }
  };

  const handleBookingPayment = () => {
    setBookingPayment(true);
  };

  return (
    <div className="travellerSide-homepage-container">
      {bookingPayment ? (
        <div className="bookingPayment-mainContainer">
          <div className="bookingPayment-mainContainer-top">
            <IoArrowBack onClick={() => setBookingPayment(false)} />
            <h3>Payment</h3>
          </div>
          <div className="bookingPayment-mainContainer-sec2">
            <div className="bookingPayment-mainContainer-sec2a">
              <h5>Paying your trip booking</h5>
              <div className="bookingPayment-mainContainer-sec2a1">
                <h4>Amount to be paid :</h4>{" "}
                <h4 className="bookingPayment-mainContainer-sec2aPrice">
                  # {ticket?.amount || ""}
                </h4>
              </div>
            </div>
          </div>
          <div className="bookingPayment-mainContainer-sec3">
            <h4>Choose payment method</h4>
            <div className="bookingPayment-mainContainer-sec3a">
              <div className="bookingPayment-mainContainer-sec3a1">
                <input
                  type="radio"
                  name="travellerPayment"
                  value="Transfer"
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      payment_type: e.target.value,
                    }))
                  }
                />
                <label>Transfer</label>
              </div>
              <div className="bookingPayment-mainContainer-sec3a1">
                <input
                  type="radio"
                  name="travellerPayment"
                  value="Bank Transfer Card"
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      payment_type: e.target.value,
                    }))
                  }
                />
                <label>Credit Card</label>
              </div>
            </div>

            <div className="bookingPayment-mainContainer-sec3b">
              <label htmlFor="">EMAIL</label>
              <div className="bookingPayment-mainContainer-sec3b1">
                <LuUser2 />
                <input
                  type="text"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="bookingPayment-mainContainer-sec4">
            <button onClick={handlePaymentTraveller}>Next</button>
          </div>
        </div>
      ) : (
        <div className="bookingDetails-holder">
          <div className="bookingDetails-holder-sec1">
            <IoArrowBack onClick={handleBack}/>
            <h3>Booking Details</h3>
          </div>
          <div className="bookingDetails-holder-sec2">
            <h5 className="bookingDetails-title">Your bus</h5>
            <div className="bookingDetails-holder-sec2a">
              <div className="bookingDetails-holder-sec2a1-left">
                <div className="bookingDetails-holder-sec2a1-lefta">
                  <div className="bookingDetails-holder-sec2a1-lefta-item1">
                    <img src={motoPng} alt="" />
                    <h5>#{ticket?._id || ""}</h5>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-lefta-item2">
                    <h6>{ticket?.date || ""}</h6>
                  </div>
                </div>
                <div className="bookingDetails-holder-sec2a1-leftb">
                  <div className="bookingDetails-holder-sec2a1-leftb1">
                    <p>{ticket?.from || ""}</p>
                    <h3>{ticket?.from_time || ""}</h3>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-leftb1">
                    <div className="bookingDetails-holder-sec2a1-leftb-dot"></div>
                    <img src={motoIcon} alt="" />
                    <div className="bookingDetails-holder-sec2a1-leftb-dot"></div>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-leftb1">
                    <h3>{ticket?.to_time || ""}</h3>
                    <p>{ticket?.to || ""}</p>
                  </div>
                </div>
                <div className="bookingDetails-holder-sec2a1-leftc">
                  <div className="bookingDetails-holder-sec2a1-leftc1">
                    <label htmlFor="">Travel time:</label>
                    <h6>
                      {calculateTravelTime(
                        ticket?.from_time || "",
                        ticket?.to_time || ""
                      )}
                    </h6>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-leftc1">
                    <label htmlFor="">No of seats booked:</label>
                    <h6>{ticket?.length || 1} seat</h6>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-leftc1">
                    <label htmlFor="">Seat no:</label>
                    <h6>Seat {ticket?._id || ""}</h6>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-leftc1">
                    <label htmlFor="">No of luggages:</label>
                    <h6>2 luggages</h6>
                  </div>
                </div>
                <div className="bookingDetails-holder-sec2a1-leftd">
                  Total: <h3>#{ticket?.amount || ""}</h3>
                </div>
              </div>

              <div className="bookingDetails-holder-sec2a1-right">
                <div className="bookingDetails-holder-sec2a1-right-top">
                  <div className="bookingDetails-holder-sec2a1-right-top-1">
                    <div className="bookingDetails-holder-sec2a1-right-top-1a">
                      <label htmlFor="Fare summary">Fare summary</label>
                      <div className="bookingDetails-holder-sec2a1-right-top-1aMain">
                        <p>Adult X {ticket?.length || 1}</p> <p>{ticket?.amount || ""}</p>
                      </div>
                    </div>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-right-top-2">
                    <div className="bookingDetails-holder-sec2a1-right-top-1aMain">
                      <p>Tax</p> <p># 0.00</p>
                    </div>
                    <div className="bookingDetails-holder-sec2a1-right-top-1aMain">
                      <p>Coupon</p> <p>None</p>
                    </div>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-right-top-3">
                    <div className="bookingDetails-holder-sec2a1-right-top-1aMain">
                      <p>Total</p> <h3>{ticket?.amount || ""}</h3>
                    </div>
                  </div>
                </div>
                <div className="bookingDetails-holder-sec2a1-right-btm">
                  <button onClick={handleBookingPayment}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
