import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoArrowBack } from "react-icons/io5";
import { addLuggage, viewBookedTicket } from '../../../../actions/authActions';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const AddLuggage = () => {
  const bookedTicketId = useSelector((state) => state.auth.bookedTicket?.ticket?._id || '');
  const token = useSelector((state) => state.auth?.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const [formData, setFormData] = useState({
    numberOfLuggages: "",
    description: "",
    photos: [], // Array of base64 strings
  });

  // Handle file selection and convert to base64
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    const fileReaders = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result); // Base64 result
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    Promise.all(fileReaders)
      .then((base64Files) => {
        setFormData((prevState) => ({
          ...prevState,
          photos: base64Files,
        }));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "File Upload Error",
          text: "An error occurred while processing the files. Please try again.",
        });
      });
  };

  // Handle other form inputs
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Submit luggage information
  const handleLuggageInfo = async () => {
    if (!formData.numberOfLuggages || !formData.description || formData.photos.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Fields",
        text: "Please fill in all fields and upload images.",
      });
      return;
    }

    try {
      const response = await dispatch(addLuggage(bookedTicketId, formData, token));

      if (response?.type === "ADD_LUGGAGE_SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "Luggage Added",
          text: "Your luggage information has been successfully added!",
        });
        dispatch(viewBookedTicket(bookedTicketId, token));
        navigate('/bookings');
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to Add Luggage",
          text: response?.payload || "Failed to add luggage. Please try again.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unexpected Error",
        text: error.message || "An unexpected error occurred. Please try again.",
      });
    }
  };

  return (
    <div className='travellerSide-homepage-container'>
      <div className="luggageInfo-mainHolder">
        <div className="luggageInfo-mainHolder-sec1">
          <IoArrowBack onClick={handleBack} /> <h3>Add Luggage Information</h3>
        </div>
        <div className="luggageInfo-mainHolder-sec2">
          <h6>ATTENTION!</h6>
          <p>
            For luggages, the maximum weight allowed in the bus is 6kg with a dimension not above
            45cm x 33cm x 20cm. Personal item bags are allowed. One luggage bag per passenger.
          </p>
        </div>
        <div className="luggageInfo-mainHolder-sec3">
          <label htmlFor="numberOfBags">Number of luggages</label>
          <select
            name="numberOfLuggages"
            id="numberOfLuggages"
            value={formData.numberOfLuggages}
            onChange={handleInputChange2}
          >
            <option value="">Select</option>
            {[1, 2, 3, 4, 5].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
        <div className="luggageInfo-mainHolder-sec4">
          <label htmlFor="description">Description of luggage(s)</label>
          <textarea
            name="description"
            id="description"
            value={formData.description}
            onChange={handleInputChange2}
          ></textarea>
        </div>
        <div className="luggageInfo-mainHolder-sec5">
          <label htmlFor="photos">Upload images of luggage</label>
          <input
            type="file"
            id="photos"
            multiple
            accept="image/*"
            onChange={handleFileChange}
          />
          <div className="luggageInfo-mainHolder-sec5-imgHlder">
            {formData.photos.length > 0 &&
              formData.photos.map((base64, index) => (
                <img
                  key={index}
                  src={base64}
                  alt={`Luggage ${index + 1}`}
                />
              ))}
          </div>
        </div>
        <div className="luggageInfo-mainHolder-sec6">
          <button onClick={handleLuggageInfo}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default AddLuggage;
