import React, { useState } from 'react'
import "./travellerContact.css"
import contactUsImg4 from"../../images/contactUsImg4.png"
import contacticon1 from "../../images/contactIcon1.svg"
import contacticon2 from "../../images/contactIcon2.svg"
import contacticon3 from "../../images/contactIcon3.svg"
import { useDispatch, useSelector } from 'react-redux'
import { contactUs } from '../../../../actions/authActions'
import Swal from 'sweetalert2'


const TravellerContactUs = () => {
  const [loading , setLoading] = useState(false)
  const dispatch = useDispatch();
  const {token, error, userId} = useSelector((state)=>state.auth)

  const [formData, setFormData] = useState({
    firstName: "", 
    email: "", 
    subject: "", 
    message: ""

  })

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const token = localStorage.getItem('token'); // Get the token from localStorage
    
    if (!token) {
        alert('You must be logged in to submit this form.');
        return; // Stop the form submission process if token is not present
    }

    const checkbox = document.getElementById('termsCheckbox');
    
    if (!checkbox.checked) {
        alert('Please agree to the terms and conditions before submitting.');
        return; // Stop the submission process if the checkbox is not checked
    }

    setLoading(true);
    dispatch(contactUs(formData, token))
        .finally(() => setLoading(false));
        setFormData({})
};


  return (
    <div className='traveller-ContactUs-container'>
        <div className="traveller-aboutUs-topSection">
          <h1>CONTACT US</h1>
        </div>
        <div className="traveller-contactUs-main">
            <form className="traveller-contactUs-leftSec" onSubmit={handleSubmit}>
              <h5>Get In Touch!</h5>
              <h2>Let’s Chat, Reach out to Us</h2>
              <input className="traveller-contact-input" type="text" placeholder='Full Name' name='firstName' value={formData.firstName} onChange={handleChange}/>
              <input className="traveller-contact-input" type="text" placeholder='Email Address' name='email' value={formData.email} onChange={handleChange}/>
              <input className="traveller-contact-input" type="text" placeholder='Subject' name='subject' value={formData.subject} onChange={handleChange}/>
              <input className='inputmessage' type="text" placeholder='Message' name='message' value={formData.message} onChange={handleChange}/>
              <div>
              <input
                 type="checkbox"
                 id="termsCheckbox"
                 name="termsCheckbox"
               />
                
                <label htmlFor="">By sending this form I confirm that I have read and accept the <span>Privacy Policy</span></label>
              </div>
              <button type='submit' disabled={loading}>
                {loading? 'sending...' : 'Submit'}
              </button>
            </form>
            <div className="traveller-contactUs-rightSec">
              <img src={contactUsImg4} alt="" />
              <div className='traveller-contactUs-rightSec-sec2'>
                  <div className="traveller-contactUs-rightSec-sec2-item">
                    <div className="traveller-contactUs-rightSec-sec2-item-leftside">
                      <img src={contacticon1} alt="" />
                    </div>
                    <div className="traveller-contactUs-rightSec-sec2-item-rightside">
                      <h5>Office address</h5>
                      <p>Nitel bustop, Festac Town Amuwo Odofin Lagos State</p>
                    </div>
                  </div>
                  <div className="traveller-contactUs-rightSec-sec2-item">
                    <div className="traveller-contactUs-rightSec-sec2-item-leftside">
                      <img src={contacticon2} alt="" />
                    </div>
                    <div className="traveller-contactUs-rightSec-sec2-item-rightside">
                      <h5>Phone number</h5>
                      <p>+234-816-401-0564</p>
                    </div>
                  </div>
                  <div className="traveller-contactUs-rightSec-sec2-item">
                    <div className="traveller-contactUs-rightSec-sec2-item-leftside">
                      <img src={contacticon3} alt="" />
                    </div>
                    <div className="traveller-contactUs-rightSec-sec2-item-rightside">
                      <h5>Email address</h5>
                      <p>contact@vadtrans.com</p>
                    </div>
                  </div>
                  
              </div>
            </div>
        </div>
    </div>
  )
}

export default TravellerContactUs
