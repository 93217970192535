import React, { useEffect, useState } from 'react'
import "./CompanyLogin.css"
import { useNavigate } from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux"
import { clearMessages, companySignup } from '../../../../actions/authActions'
import Swal from 'sweetalert2'
import { IoMdEye } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa6";


const CompanySignUpPage1 = () => {
  const [visible, setVisible] = useState(false)
  const [formData, setFormData] = useState({
    companyName: "",
    companyAcronym: "",
    email:"",
    password:"",
    confirmPassword:"",
    phoneNumber:''
  })
  const [loading, setLoading] = useState(false)
  const [continueBtn, setContinueBtn] = useState(true)
  const [nextBtn, setNextBtn] = useState(false)
  const [signupBtn, setSignupBtn] = useState(false)
  const { error, message } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateForm =()=>{
    if(!formData.email){
      Swal.fire('Error', 'Email is required', 'error');
      return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      Swal.fire('Error', 'Enter a valid email', 'error');
      return false;
    }
    if(!formData.phoneNumber){
      Swal.fire('error', 'Company Phone Number is required',  'error');
      return false;
    }
    return true;
  };

  const validateForm1 = () =>{
    if(!formData.companyName){
      Swal.fire('error', 'Company Name is required',  'error');
      return false;
    }
    if (!formData.companyAcronym){
      Swal.fire('error', 'company accronym is required', 'error');
      return false;
    }
    return true
  }

  const validateForm2= ()=>{
    if (!formData.password) {
      Swal.fire('Error', 'Password is required', 'error');
      return false;
    }
    if (formData.confirmPassword !== formData.password){
      Swal.fire('error', "password does not match", 'error');
      return false;
    }
    return true
  }


  const handleChange = (e)=>{
   setFormData({
    ...formData,
    [e.target.name]:e.target.value,
   });
  };

  const handleContinueBtn = ()=>{
    if(validateForm()){
      setNextBtn(true)
      setContinueBtn(false)
      setSignupBtn(false)
    }
  }

  const handleNextBtn = ()=>{
    if(validateForm1()){
      setNextBtn(false)
      setSignupBtn(true)
    }
    
  }

   const handleContinue = (e) => {
          e.preventDefault();
          if (validateForm2()) {
              setLoading(true); 
              dispatch(companySignup(formData))
                  .finally(() => setLoading(false)); 
          }
      };

  useEffect(() => {
      if (message) {
        Swal.fire('Success', message || 'Signup Successful', 'success').then(() => {
          dispatch(clearMessages()); 
          navigate('/companylogin')
        });
      } else if (error) {
        Swal.fire('Error', error || 'An error occurred').then(() => {
          dispatch(clearMessages()); 
        });
      }
    }, [ message, error, navigate]);


  return (
   <form onSubmit={handleContinue}>
     <div className='comapnyLogin-container'>
      {continueBtn&&
          <div className='company-login-content'>
          <h2>Create your account</h2>
          <p>Create an account to list your transport company</p>
          <div className='login-email-holder'>
            <label>Email</label>
            <input type="email"  name="email" value={formData.email} onChange={handleChange} />
          </div>
          <div className='login-email-holder'>
            <label>Phone Number</label>
            <input type="email"  name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
          </div>
          <button type='button' onClick={handleContinueBtn}> Continue</button>
          <div className='terms-and-condtion-holder'>
            <input type="checkbox" /><label>I agree to <span onClick={() => navigate('/termsandcondition')}>Terms</span> and <span onClick={() => navigate('/termsandcondition')}>Privacy Policy</span></label>
          </div>
          <div className='already-have-account'>
          <p>Already have an account? <span onClick={() => navigate('/companylogin')}>Login</span></p>
          </div>
        </div>
}
        {
          nextBtn&& 
           <div className='company-login-content'>
          <h2>Contact Details</h2>
          <p>Your Company name and phone number are needed to ensure the security of your Booking.com account.</p>
          <div className='login-email-holder'>
            <label>Company Name</label>
            <input type="text" name='companyName' value={formData.companyName} onChange={handleChange} />
          </div>
          <div className='login-email-holder'>
            <label>Company Acronym</label>
            <input type="text" name='companyAcronym' value={formData.companyAcronym} onChange={handleChange} />
          </div>
    
          <button type='button'onClick={handleNextBtn} > Next</button>
         
        </div>
        } {signupBtn&&
           <div className='company-login-content'>
           <h2>Create Password</h2>
           <h5>Use a minimum of 10 characters, including uppercase letters, lowercase letters and numbers.</h5>
           <div className='login-email-holder' >
             <label>Password</label>
             <div className="passContain2">
                                                       <input className='password-inputHolder3' type={visible ? "text" : 'password'} name="password" value={formData.password} onChange={handleChange} placeholder='Password' />
                                                       {visible ? < FaEyeSlash  onClick={()=>setVisible(false)} /> : < IoMdEye   onClick={()=>setVisible(true)}/>}
                                                       </div>
             
           </div>
           <div className='login-email-holder'>
             <label>Comfirm Password</label>
             <div className="passContain2">
                                                       <input className='password-inputHolder3' type={visible ? "text" : 'password'}  name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} placeholder='Confirm Password' />
                                                       {visible ? < FaEyeSlash  onClick={()=>setVisible(false)} /> : < IoMdEye   onClick={()=>setVisible(true)}/>}
                                                       </div>
             
           </div>
           
           <button type='submit' disabled={loading} > {loading? 'loading... ':'Create account'}</button>
          
         </div>
      }
      

      

     
    </div>
   </form>
  )
}

export default CompanySignUpPage1
