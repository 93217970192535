import React, { useEffect, useState } from 'react';
import "./CompanyUpload.css";
import searchIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/search.svg";
import filterIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/filter.svg";
import navIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/nav.svg";
import doubleArrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-left.svg";
import arrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-left.svg";
import arrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-right.svg";
import doubleArrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-right.svg";
import { useSelector, useDispatch } from "react-redux";
import { addTerminals, uploadBusImages } from '../../../../actions/authActions';
import Swal from 'sweetalert2';
import { viewTerminals } from '../../../../actions/authActions';
import { MdOutlineMoreHoriz } from "react-icons/md";

const CompanyUpload = () => {
  const [companyImage, setCompanyImage] = useState(true);
  const [companyTerminal, setCompanyTerminal] = useState(false);
  const [terminalSaveBtn, setTerminalSaveBtn] = useState(true);
  const [terminalAddBtn, setTerminalAddBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([null, null, null]);
  const [previewUrls, setPreviewUrls] = useState([null, null, null]);
  const [formData, setFormData] = useState({
    state: '',
    terminal: '',
    phoneNumber: '',
    email: '',
    address: ''
  });

  useEffect(()=>{
  if (userId && token){
    dispatch(viewTerminals(userId, token))
    .catch((error)=>{
     console.error('failed to fetch terminals:', error)
     });
    } else {
      console.warn('userId or token not found')
    }
  })
  const dispatch = useDispatch();
  // const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.userData);
  // const userId = useSelector((state) => state.auth.userId);
  const token=localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  const validateForm = () => {
    const { state, terminal, phoneNumber, email, address } = formData;
    if (!state) {
      Swal.fire('Error', 'Terminal State is required', 'error');
      return false;
    }
    if (!terminal) {
      Swal.fire('Error', 'Terminal Name is required', 'error');
      return false;
    }
    if (!phoneNumber) {
      Swal.fire('Error', 'Terminal Phone Number is required', 'error');
      return false;
    }
    if (!email) {
      Swal.fire('Error', 'Terminal Email is required', 'error');
      return false;
    }
    if (!address) {
      Swal.fire('Error', 'Terminal Address is required', 'error');
      return false;
    }
    return true; // Added return for valid form
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        await dispatch(addTerminals(formData, userId, token)); // Assuming this returns a promise
        Swal.fire('Success', 'Terminal added successfully', 'success');
        setFormData({ state: '', terminal: '', phoneNumber: '', email: '', address: '' }); // Reset form
      } catch (error) {
        Swal.fire('Error', 'Failed to add terminal', 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const updatedImages = [...images];
      updatedImages[index] = file;
      setImages(updatedImages);

      const updatedPreviews = [...previewUrls];
      updatedPreviews[index] = URL.createObjectURL(file);
      setPreviewUrls(updatedPreviews);
    }
  };

  const handleUpload = () => {
    if (images.some((img) => img === null)) {
      alert('Please select all 3 images before saving.');
      return;
    }
    dispatch(uploadBusImages(images, userId, token));
  };

  const handleCompanyTerminal = () => {
    setCompanyTerminal(true);
    setCompanyImage(false);
  };

  const handleCompanyImage = () => {
    setCompanyImage(true);
    setCompanyTerminal(false);
  };

  const handleTerminalSaveBtn = () => {
    if (formData.state) {
      setTerminalSaveBtn(false);
      setTerminalAddBtn(false);
    } else {
      Swal.fire('Error', 'State is required', 'error');
    }
  };

  const handleTerminalAddBtn = () => {
    setTerminalAddBtn(true);
    setTerminalSaveBtn(false);
  };

  return (
    <div className='companyUpload-container'>
      <div className='company-dash-toplayer'></div>
      <div className='companyUpload-header'>
        <h4 className={companyImage ? "companyUpload-header-h4-active" : 'companyUpload-header-h4'} onClick={handleCompanyImage}>Images</h4>
        <h4 className={companyTerminal ? "companyUpload-header-h4-active" : 'companyUpload-header-h4'} onClick={handleCompanyTerminal}>Terminals</h4>
      </div>
      {companyImage ? 
        <>
          <div className='companyUpload-mainContent'>
      <h4>Upload Images of Buses</h4>
      <div className='companyUpload-mainContent-section1'>
        {[1, 2, 3].map((imgNum, index) => (
          <div className='companyUpload-mainContent-item' key={imgNum}>
            <p>Image {imgNum}</p>
            <div className='companyUpload-mainContent-item-imgHolder'>
              {previewUrls[index] ? (
                <img src={previewUrls[index]} alt={`Upload Image ${imgNum}`} />
              ) : (
                <p>No Image Selected</p>
              )}
            </div>
            <input
              type='file'
              accept='image/*'
              onChange={(e) => handleFileChange(e, index)}
            />
          </div>
        ))}
      </div>
      <button className='saveBtn' onClick={handleUpload}>
        Save
      </button>
    </div>
        </> :
        <>
          {terminalSaveBtn ? 
            <div className='company-terminal2-container'>
              <h3>Terminal Locations</h3>
              <div className='company-terminalq-addBtn'>
                <button onClick={handleTerminalAddBtn}>Add New</button>
              </div>
              <div className='clientManagement-history'>
                <div className='clientManagement-history-searchbar'>
                  <input type="text" />
                  <img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
                  <div className='filterContainer'>
                    <img className='filterContainer-img' src={filterIcon} alt="" />
                  </div>
                </div>
                <div className='clientManagement-history-labels2'>
                  <div className='company-name2'>State</div>
                  <div className='Company-terminal2'>Terminals</div>
                  <div className='company-date12'>Date</div>
                </div>
                <div className='clientManagement-list-holder'>
                  <div className='clientManagement-list-item2'>
                    <div className='company-name2'>Lagos</div>
                    <div className='Company-terminal2'>15</div>
                    <div className='company-date12'>04 Oct 2023 | 23:18</div>
                    <div className='clientManagement-list-item-img2-container'>
                      <img className='clientManagement-list-item-img2' src={navIcon} alt="nav Icon" />
                    </div>
                  </div>
                </div>
                <div className='clientManagement-history-navigation'>
                  <div className='clientManagement-history-item'>
                    <img src={doubleArrowLeft} alt="" />
                  </div>
                  <div className='clientManagement-history-item2'>
                    <img src={arrowLeft} alt="" />
                  </div>
                  <div className='clientManagement-history-item2-active'>
                    <p>1</p>
                  </div>
                  <div className='clientManagement-history-item2'>
                    <p>2</p>
                  </div>
                  <div className='clientManagement-history-item2'>
                    <img src={arrowRight} alt="" />
                  </div>
                  <div className='clientManagement-history-item3'>
                    <img src={doubleArrowRight} alt="" />
                  </div>
                </div>
                <div className="company-teminal-item">
                    <div className="company-teminal-item1">
                    <MdOutlineMoreHoriz />
                    <div className="company-teminal-item1a">
                      <p>State</p>
                      <p>Lagos</p>
                    </div>
                    </div>
                    <div className="company-teminal-item2">
                      <p>Terminals</p>
                      <p style={{color:'red'}}>15 Terminals</p>
                    </div>
                    <div className="company-teminal-item2">
                      <p>Date</p>
                      <p >15/12/2024</p>
                    </div>
                </div>
              </div>
            </div>
            : terminalAddBtn ? 
            <div className='company-upload-terminalContainer'>
              <h4>Upload Terminal information</h4>
              <div className='company-upload-terminalpg1-input'>
                <label>State</label>
                <input type="text" name='state' value={formData.state} onChange={handleChange} />
              </div>
              <button className='saveBtn2' onClick={handleTerminalSaveBtn}>Save</button>
            </div> :
            <>
              <form className='addNew-terminal-Container' onSubmit={handleSubmit}>
                <h4>Add New Terminal</h4>
                <div className='addNewterminal-section1'>
                  <div className="addnewTermina-section1-item-holder">
                    <label>Terminal</label>
                    <input type="text" name='terminal' value={formData.terminal} onChange={handleChange} />
                  </div>
                </div>
                <div className='addNewterminal-section2'>
                  <div className="addnewTermina-section1-item-holder">
                    <label>Phone Number</label>
                    <input type="text" name='phoneNumber' value={formData.phoneNumber} onChange={handleChange} />
                  </div>
                  <div className="addnewTermina-section1-item-holder">
                    <label>E-mail</label>
                    <input type="email" name='email' value={formData.email} onChange={handleChange} />
                  </div>
                </div>
                <div className='addNewterminal-section1'>
                  <div className="addnewTermina-section1-item-holder2">
                    <label>Address</label>
                    <input type="text" name='address' value={formData.address} onChange={handleChange} />
                  </div>
                </div>
                <button className='saveBtn2' type='submit' disabled={loading}>
                  {loading ? 'Saving...' : 'Save'}
                </button>
              </form>
            </>
          }
        </>
      }
    </div>
  );
}

export default CompanyUpload;
