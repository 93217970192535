import React, { useState } from 'react'
import "./CompanyHeader.css"
import companyLogo from "../images/logoBndW.svg"
import searcIcon from "../images/searchWhite.svg"
import { useNavigate } from 'react-router-dom'
import { MdOutlineCancel } from "react-icons/md";
import { IoIosMenu } from "react-icons/io";


const CompanyHeader = () => {
  const navigate = useNavigate()
  const [menuIsTrue, setMeuIsTrue] = useState(false)
  const handleMenuTrue = ()=>{
    setMeuIsTrue(true)
  }
  const handleMenuFalse = ()=>{
    setMeuIsTrue(false)
  }
  const handleLoginasCompany=()=>{
    navigate("/companylogin")
  } 
  
  const handleRegisterCompany=()=>{
    navigate("/companysignuppage1")
  }

  return (
    <div className='companyHeader-container'>
      <img src={companyLogo} alt=""  />
      <div className='company-header-text'>
        <p>Contact Us</p>
        <p>Already Listed?</p>
        <button onClick={handleLoginasCompany}>Login</button>
        <img src={searcIcon} alt=""  />
      </div>
      <div className="company-header-text2-mobile">
        {menuIsTrue? <MdOutlineCancel onClick={handleMenuFalse} size={20} color='white'/> : <IoIosMenu size={20} color='white' onClick={handleMenuTrue}/> }   
      </div>

      {
        menuIsTrue &&
        <div className="company-header-text2-mobile-item">
          <p onClick={handleRegisterCompany}>Sign up</p>
          <p onClick={handleLoginasCompany}>Login</p>
      </div>
      }
      
    </div>
  )
}

export default CompanyHeader
