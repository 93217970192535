import React, { useEffect, useState } from 'react'
import "./CompanyHeader.css"
import companyLogo from "../images/logoW.svg"
import searcIcon from "../images/searchBlc.svg"
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserData, logout } from '../../../actions/authActions'
import { FaAngleDown } from "react-icons/fa6";
import notification from "../images/Notification.png"
import { FaUser } from "react-icons/fa";
import { TbMenuDeep } from "react-icons/tb";
// import handleCompanyUserProfile from '../pages/CompanyDasshboard'

// import searchIcon from "../../DriverSide/Component/"

const CompanyHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const [menuIsTrue, setMenuIsTrue] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false)
    const [userProfileOption, setUserProfileOption] = useState(false);
  const userData = useSelector((state)=> state.auth.userData);
  const  isAuthenticated = useSelector ((state)=>state.auth.isAuthenticated);

  useEffect(()=>{
    if (isAuthenticated) {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      if (userId && token) {
          dispatch(fetchUserData(userId, token, 'transport_company'))
              .catch((error) => {
                  console.error('Failed to fetch user data:', error);
              });
      } else {
          console.warn('UserID or Token not found in localStorage.');
      }
  }
  }, [dispatch, isAuthenticated]);

const handleUserProfileOptionBtn = () => {
  setUserProfileOption(!userProfileOption);
};

const handleMenuDrop = ()=>{
  setMenuDrop(!menuDrop)
}
  const handleLoginasCompany=()=>{
    navigate("/companylogin")
  }
  const handleNotificationIcon=()=>{
    navigate('./notifications')
  }
  const handleProfileBtn = () => {
    navigate('/userprofile');
    setMenuIsTrue(false);
};
const handleLogout = () => {
    dispatch(logout());
    navigate('/companylogin');
};
  return (
    <div className='companyHeader-container'>
      <img src={companyLogo} alt="" onClick={()=> navigate('/companyside')} />

      {/* <div className='mobile-menuHolder'><TbMenuDeep size={20} onClick={handleMenuDrop}/></div>
      {menuDrop &&
      <div className="menuList_company">

      </div> } */}

      
      {isAuthenticated ? 
      <div className="traveller-header-bottom-item3">
          <p>Contact us</p>
      <div className="travelle-header-sec3Items">
          <img className='searchIcon' src={notification} alt="notification" onClick={() => navigate('/notifications')} />
      </div>
      <div className="travelle-header-sec3Items2">
          <img className="travelle-header-sec3Items2-img" src={userData?.data?.url || <FaUser/>} alt="user" />
          <p >{userData?.data?.companyName || 'User'}</p>
          <FaAngleDown onClick={handleUserProfileOptionBtn} />
      </div>
      <div className="travelle-header-sec3Items">
          <img className='searchIcon' src={searcIcon} alt="search" />
      </div>
  </div>
      
      :
       <div className='company-header-text2'>
       <p>Contact Us</p>
       <p>Already Listed?</p>
       <button onClick={handleLoginasCompany}>Login</button>
       <img src={searcIcon} alt="" onClick={handleNotificationIcon} />
     </div>

      }
     {
                userProfileOption&& (
                    <div className='userProfile-option-holder2'>
                                {/* <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p className='menuItems-lists-Holder-btmSec-item2-p' onClick={()=>{handleProfileBtn(); setUserProfileOption(false);}}>Profile</p>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p className='menuItems-lists-Holder-btmSec-item2-p' onClick={() => {navigate('/editprofile'); setUserProfileOption(false);}}>Edit profile</p>
                                </div> */}
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p className='menuItems-lists-Holder-btmSec-item2-p' 
                                    onClick={() => { handleLogout(); setUserProfileOption(false); }}>Logout</p>
                                </div>
                            </div>
                )
            }
    </div>
  )
}

export default CompanyHeader
