import React, { useEffect } from 'react'
import "./TravellerNotifications.css"
import leftArrow from "../../../Company/images/arrowLeft.svg"
import notifyMe from "../../../Company/images/notifyMe.svg"
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationsAdmin } from '../../../../actions/authActions'
import { useNavigate } from 'react-router-dom'


const TravellerNotification = () => {
  const token = useSelector((state)=>state.auth?.token)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(()=>(
    dispatch(getNotificationsAdmin(token))
  ) [token])

  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      alert("No previous page in history!");
    }
  };
  const myNotifications = useSelector((state)=>state.auth?.notifications?.data || [])
  return (
    <div className='company-notification-container'>
      <div className="comNot-main">
        <div className='comNot-headline-conatiner'>
          <img src={leftArrow} alt="" onClick={handleBack} style={{cursor:'pointer'}} />
          <h2>Notifications</h2>
        </div>
        <div className='comNot-filter-conatiner'>
          <select name="" id="">
            <option value="">Recent</option>
            <option value="">Older</option>
            <option value="">Unread</option>
            <option value="">Read</option>
          </select>
        </div>
        <div className='comNot-subheadline-conatiner'></div>
        <div className='comNot-item-conatiner'>
        {
    myNotifications && myNotifications.length > 0 ? (
        myNotifications.map((notificationz) => (
            <div className='comNot-item' key={notificationz.id}> {/* Ensure each item has a unique key */}
                <img src='/notIcon.png' alt="Notification" />
                <p>{notificationz.subject}<span>here</span></p>
                <span>{notificationz.time}</span>
            </div>
        ))
    ) : (
        <p>You don't have any notifications now.</p>
    )
}

          
        </div>
      </div>
      
    </div>
  )
}

export default TravellerNotification
