import React, { useState } from 'react'
import bus from "../../images/bus.png"
import plus from "../../images/plus.svg"
import mixedArrow from "../../images/mixedArrow.svg"
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import {useReducer, useSelector} from "react-redux"
import { useNavigate } from 'react-router-dom';



const HeroPage = () => {
  const tripLists = useSelector((state)=>state.auth?.trips?.data || []) 
  const userId = useSelector((state)=>state.auth?.userData?.data?._id)
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [userSearch, setUserSearch] = useState(false)
  const token = useSelector((state)=>state.auth?.token)
  const navigate=useNavigate()

const [searchInput, setSearchInput] = useState({
  to: '',
  from:'',
  adult: "1",
  children: "0",
  date: '',
  tripType: 'One Way',
  from_time: '',
  to_time: ''
})

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setSearchInput((prev) => ({ ...prev, [name]: value }));
};

const handleTravellerSearchBtn = () => {
  if(searchInput){
    localStorage.setItem('searchInput', JSON.stringify(searchInput))
  }
  const { to, from } = searchInput;
  const results = tripLists.filter(
    (trip) =>
      trip.to &&
      trip.from &&
      trip.to.toLowerCase() === to.toLowerCase() &&
      trip.from.toLowerCase() === from.toLowerCase()
  );
  localStorage.setItem('filteredTrip', JSON.stringify(results)
  )
  if(results){
  navigate('/searchedtrips');
  }
};

  return (
    <div className='travellerSide-homepage-container'>
      <div className="traveller-homepage-hero">
        <h2>Where do you want to go?</h2>
        <div className="traveller-homepage-hero-item">
          <select>
            <option value="">one-way</option>
            {/* <option value="">round trip</option> */}
          </select>
          <select
            name="adult"
            value={searchInput.adult}
            onChange={handleInputChange}
          >
            <option value="1">1 Adult</option>
            <option value="2">2 Adults</option>
            <option value="3">3 Adults</option>
            <option value="4">4 Adults</option>
            <option value="5">5 Adults</option>
          </select>
        </div>
        <div className="traveller-heropage-tripSearch">
          <div className='traveller-homepage-tripsearch-item'>
            <img src={bus} alt="" />
            <input type="text" name="from" value={searchInput.from} onChange={handleInputChange} placeholder='From?' />
            <img src={plus} alt="" />
          </div>
          <div className="traveller-homepage-tripsearch-item2">
            <img src={mixedArrow} alt="" />
          </div>
          <div className='traveller-homepage-tripsearch-item'>
            <img src={bus} alt="" />
            <input type="text" name="to" value={searchInput.to} onChange={handleInputChange} placeholder='To?' />
            <img src={plus} alt="" />
          </div>
          <div className='traveller-homepage-tripsearch-item3'>
            
            <input type="date" name='date' value={searchInput.date} onChange={handleInputChange} placeholder='date' />
            
          </div>
          <button className='traveller-homepage-tripsearch-btn' onClick={handleTravellerSearchBtn}>Search</button>
         
        </div>
        <div className='traveller-homepage-tripsearch-item-btnHolder'>
           <button className='.traveller-homepage-tripsearch-btn' onClick={handleTravellerSearchBtn}>Search</button>
          </div>
      </div>
    </div>
  )
}

export default HeroPage