import React, { useEffect, useState, useMemo } from 'react';
import "./driversignup.css";
import { useNavigate } from 'react-router-dom';
import { driverSignup, getAllCompany } from '../../../../actions/authActions';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

const DriverSignup = () => {
  const [driverPassword, setDriverPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName:'',
    lastName:'',
    confirmPassword: '',
    email: '',
    password: '',
    phoneNumber: '',
    transCompany: '',
  });

  useEffect(() => {
    dispatch(getAllCompany()).catch((error) => {
      console.error('Failed to fetch transport companies:', error);
    });
  }, [dispatch]);
  // Memoize the transportCompanies to avoid unnecessary re-renders
  const transportCompanies = useSelector(state => state.auth.transportCompanies || []);
  const memoizedTransportCompanies = useMemo(() => transportCompanies.data, [transportCompanies]);
  const validateFormData = () => {
    if (!formData.firstName) {
      Swal.fire('Error', 'First Name is required', 'error');
      return false;
    }
    if (!formData.lastName) {
      Swal.fire('Error', 'Last Name is required', 'error');
      return false;
    }
    if (!formData.email) {
      Swal.fire('Error', 'Email is required', 'error');
      return false;
    }
    if (!formData.phoneNumber) {
      Swal.fire('Error', 'Phone Number is required', 'error');
      return false;
    }
    if (!formData.transCompany) {
      Swal.fire('Error', 'Transport company is required', 'error');
      return false;
    }
    return true;
  };

  const validateForm2 = () => {
    if (!formData.password) {
      Swal.fire('Error', 'Password is required', 'error');
      return false;
    }
    if (formData.confirmPassword !== formData.password) {
      Swal.fire('Error', 'Passwords do not match', 'error');
      return false;
    }
    return true;
  };

  const handleDriverPassword = () => {
    if (validateFormData()) {
      setDriverPassword(true);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignup = () => {
    if (validateForm2()) {
      setLoading(true);
      dispatch(driverSignup(formData))
        .then(() => {
          Swal.fire('Success', 'Driver signed up successfully!', 'success');
          navigate('/driverlogin');
        })
        .catch((error) => {
          Swal.fire('Error', error.message, 'error');
        })
        .finally(() => setLoading(false));
    }
  };

  const handleLoginBtn = () => {
    navigate("/driverlogin");
  };

  useEffect(() => {
    dispatch(getAllCompany()).catch((error) => {
      console.error('Failed to fetch transport companies:', error);
    });
  }, [dispatch]);

  return (
    <form className='driverSignup-holder' onSubmit={handleSignup}>
      {driverPassword ? (
        <div className="driverSignup-mainContainer2">
          <div className="driverSignup-mainContainer-item1">
            <h2>Create Password</h2>
            <h5>Use a minimum of 10 characters, including uppercase letters, lowercase letters, and numbers.</h5>
          </div>
          <div className="driverSignup-mainContainer-item2">
            <label>Password</label>
            <input
              type="password"
              name='password'
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className="driverSignup-mainContainer-item2">
            <label>Confirm Password</label>
            <input
              type="password"
              name='confirmPassword'
              value={formData.confirmPassword}
              onChange={handleChange}
            />
          </div>
          <button type='button' onClick={handleSignup} disabled={loading}>
            {loading ? 'Creating...' : 'Create Account'}
          </button>
        </div>
      ) : (
        <div className="driverSignup-mainContainer">
          <div className="driverSignup-mainContainer-item1">
            <h2>Create your VadTrans Driver account</h2>
            <h5>Create a driver’s account on Vadtrans</h5>
          </div>
          <div className="driverSignup-mainContainer-item2">
            <label>First Name</label>
            <input
              type="text"
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="driverSignup-mainContainer-item2">
            <label>Last Name</label>
            <input
              type="text"
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="driverSignup-mainContainer-item2">
            <label>Email Address</label>
            <input
              type="text"
              name='email'
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="driverSignup-mainContainer-item2">
            <label>Phone Number</label>
            <input
              type="text"
              name='phoneNumber'
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="driverSignup-mainContainer-item2">
            <label>Transport Company</label>
            <select
              name="transCompany"
              value={formData.transCompany}
              onChange={handleChange}
            >
              <option value="" disabled>Select</option>
              {/* Dynamically render transport company options */}
              {Array.isArray(memoizedTransportCompanies) && memoizedTransportCompanies.length > 0 ? (
                memoizedTransportCompanies.map((company) => (
                  <option key={company.tranCompanyID} value={company.tranCompanyID}>
                    {company.companyName}
                  </option>
                ))
              ) : (
                <option value="" disabled>No companies available</option>
              )}
            </select>
          </div>
          <button type='button' onClick={handleDriverPassword}>
            Continue
          </button>
          <div className="driverSignup-mainContainer-item3">
            <input type="checkbox" />
            <label>
              I agree to <span>Terms</span> and <span>Privacy Policy</span>
            </label>
          </div>
          <label>
            Already have an account? <span onClick={handleLoginBtn}>Sign in</span>
          </label>
        </div>
      )}
    </form>
  );
};

export default DriverSignup;
