import React from 'react'
import { Outlet} from 'react-router-dom'
import HeaderTraveller from '../../components/HeaderTraveller'

import CompanyFooter2 from '../../../Company/components/CompanyFooter2'
import HeroPage from '../TravelerHomepage/HeroPage'



const Layout2a = () => {
  return (
    <div>
      <HeaderTraveller/>
      <HeroPage/>
      <main>
        <Outlet/>
      </main>
      <CompanyFooter2/>
    </div>
  )
}

export default Layout2a
