import React, { useState, useEffect } from 'react'
import "./travellerHomepage.css"
import bus from "../../images/bus.png"
import plus from "../../images/plus.svg"
import mixedArrow from "../../images/mixedArrow.svg"
import sect3Img from "../../images/section3Img.svg"
import { useNavigate } from 'react-router-dom'
import motoPng from "../../images/company1.png"
import motoIcon from "../../images/busIcon.png"
import { IoArrowBack } from "react-icons/io5";
import driverPics from "../../images/driver.png"
import { LuUser2 } from "react-icons/lu";
import { CiCreditCard1 } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { CiLock } from "react-icons/ci";
import { addLuggage, bookTicket, fetchAvailableSeats, getAllCompany, getAvailableSeats, selectSeat, viewBookedTicket, viewTripsTraveller } from '../../../../actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { IoMdStar } from "react-icons/io";
import Swal from 'sweetalert2'



const TravellerHomepage = () => {
  const [userSearchDetails, setUserSearchDetails] = useState(false)
  const [luggageInfo, setLuggageInfo] = useState(false)
  const [bookingDetails, setBookingDetails] = useState(false)
  const [bookingPayment, setBookingPayment] = useState(false)
  const transportCompanyList = useSelector((state)=>state.auth?.transportCompanies?.data || [])
  const token = useSelector((state)=>state.auth?.token)
  const userId = useSelector((state)=>state.auth?.userData?.data?._id)
 
  const displayedCompanies =  transportCompanyList.slice(0, 3)
  const ticketId = useSelector((state)=>state.auth.bookedTicket?.ticket?._id || '')
  const ticket = useSelector((state)=>state.auth?.ticket || [])
  
 
  const navigate = useNavigate()
  const dispatch = useDispatch()

 

  const handleCompanyDetails = (displayedCompany) => {
    navigate('/transportcompanydetails', { state: { tranCompanyID: displayedCompany.tranCompanyID } });
  };
  
  const HandleTransportCompanies = ()=>{
    navigate('/transportcompanies')
  }

    const HnadleContactUsBtn = ()=>{
      navigate('/contactus')
    }

    const HandleAboutUsBtn = () =>{
      navigate('/aboutus')
    }
    useEffect(() => {
      dispatch(getAllCompany())
              
  }, [dispatch]);

  useEffect(() => {
    dispatch(viewTripsTraveller())
            
}, [dispatch]);

  return (
    <div className='travellerSide-homepage-container'>    
        <div className="traveller-non-heroHolder">
      <div className="traveller-homepage-section2">
        <div  className='traveller-homepage-section2-top'>   
          <h2>Transportation Companies</h2>
          <div className='traveller-homepage-section2-redline'></div>
        </div>
        <div className='traveller-homepage-section2-cardholder'>
          {
            displayedCompanies.map((displayedCompany)=>(
              <div className='card1-Holder' key={displayedCompany.tranCompanyID} onClick={() => handleCompanyDetails(displayedCompany)}>
              <div className="card1-imgHolder">
              <img 
                    src={displayedCompany?.url && displayedCompany.url.trim() !== "" ? displayedCompany.url : '/comLogo.png'} 
                    alt="Company Logo" 
                    onError={(e) => { e.target.src = '/comLogo.png'; }} 
/>

              </div>
              <div className="card1-details">
                  <h2 className='card-details-title'>{displayedCompany.companyName}</h2>
                  <p className='card-details-destination'>Destinations : Lagos, Abuja, Port Harcourt, Ibadan,Enugu </p>
                  <p className='card-details-price'>Starting from #11,500</p>
                  <p className='card-details-rating'>4.6 <IoMdStar /> </p>
              </div>
                    </div>
            ))
          }
        
        </div>
        <div className="traveller-homepage-section2-btn">
           <button onClick={HandleTransportCompanies}>View More</button>
       </div>
      </div>
      <div className="traveller-homepage-section3">
      <div  className='traveller-homepage-section2-top'>   
          <h2>About VadTrans</h2>
          <div className='traveller-homepage-section2-redline'></div>
        </div>
        <div className="traveller-homepage-section3-main">
          <div className="traveller-homepage-section3-leftMain">
            <img src={sect3Img} alt="" />
          </div>
          <div className="traveller-homepage-section3-rightMain">
            <p>Vadtrans was founded on the belief that transportation should be more than just a means of getting from point A to point B. We aim to create a seamless, enjoyable, and sustainable experience that transport people from one place to another.</p>
            <p>We are committed to providing an exceptional travel experience to our customers. <br />We strive to create a great products and quality service that supersedes our customer’s taste. <br />We embrace cutting-edge technology and innovative solutions.</p>
          <span onClick={HandleAboutUsBtn}  >view more</span>
          </div>
        </div>
      </div>
      <div className="traveller-homepage-section4">
        <div className="traveller-homepage-section4-main">
          <h1>Want to know more?</h1>
          <button onClick={HnadleContactUsBtn}> Contact us</button>
        </div>
      </div>
      </div>
     
      
    </div>
  )
}

export default TravellerHomepage
