import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearMessages, createTicket, viewCompanyTickets } from '../../../../actions/authActions'
import "./CompanyTickets.css"

import searchIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/search.svg"
import filterIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/filter.svg"
import navIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/nav.svg"
import doubleArrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-left.svg"
import arrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-left.svg"
import arrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-right.svg"
import doubleArrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-right.svg"
import arrorRightRed from "../../images/arrowRightRed.svg"
import printLogo from "../../images/print.png"
import gigmLogo from "../../images/GigmLogo2.svg"
import ticketYellow from "../../images/tickYellow.svg"
import barCode from "../../images/barcode.svg"
import Swal from 'sweetalert2'


const CompanyTickets = () => {

const dispatch = useDispatch();
const [companyTicketsDetails, setCompanyTicketsDetails] = useState(true)
const token = localStorage.getItem('token'); 
const userId = localStorage.getItem('userId'); 
const tickets = useSelector((state) => state.auth.ticket?.data || []);
const [addTicktetBtn, setAddTicket] = useState(false)
const { error, message } = useSelector((state) => state.auth);
const [loading, setLoading] = useState(false)
const [formData, setFormData] = useState({
      from :'',
      to :'',
      tripType :'',
      amount :'',
      from_time :'',
      to_time :'',
})

const handleChange = (e)=>{
  setFormData({
    ...formData,
    [e.target.name] : e.target.value,
  });
};

const validateForm =()=>{
  if(!formData.to){
    Swal.fire('Error', 'destination state is required', 'error');
    return false;
  }if(!formData.from){
    Swal.fire('Error', "departure state is required", 'error')
    return false
  }if(!formData.tripType){
    Swal.fire('Error', "trip type is required", 'error')
    return false
  }if(!formData.from_time){
    Swal.fire('Error', "departure time is required", 'error')
    return false
  }if(!formData.to_time){
    Swal.fire('Error', "arrival time is required", 'error')
    return false
  }if(!formData.amount){
    Swal.fire('Error', "amount is required", 'error')
    return false
  }
  return true
}


const handleSaveTicketBtn= (e)=>{
  e.preventDefault();
  if(validateForm()){
    setLoading(true)
    dispatch(createTicket(formData, userId, token))
      .finally(()=>setLoading(false));
  }
};
const handleAddTicketBtn = (()=>{
  setAddTicket(true)
})
const handleCompanyTicketdetails = ()=>{
  setCompanyTicketsDetails(false)
}

useEffect(() => {
  if (userId && token) {
    // Dispatch viewTerminals and handle errors
    dispatch(viewCompanyTickets(userId, token))
      .catch((error) => {
        console.error('Failed to fetch terminals:', error);
      });        

  } else {
    console.warn('userId or token not found');
  }
}, [userId, token]);

useEffect(() => {
      if (message) {
        Swal.fire('Success', message || 'Ticket Created Successfully', 'success').then(() => {
          dispatch(clearMessages()); 
        });
      } else if (error) {
        Swal.fire('Error', error || 'An error occurred').then(() => {
          dispatch(clearMessages()); 
        });
      }
    }, [ message]);

  return (
    <div className='CompanyTickets-container'>
      <div className='company-dash-toplayer'></div>
      {companyTicketsDetails? 
      
      <>
      {addTicktetBtn? <>
        <h3>Add New Ticket</h3>
        <form className='newTicketForm' onSubmit={handleSaveTicketBtn}>
          <input type="text" placeholder='from (e.g lagos)'  name='from' value={formData.from}   onChange={handleChange}/>
          <input type="text" placeholder='to (e.g abuja)' value={formData.to} name='to' onChange={handleChange}/>
          <input type="text" placeholder='tripType ( e.g One Way)' value={formData.tripType} name='tripType' onChange={handleChange} />
          <input type="text"  placeholder='amount ' value={formData.amount} name='amount' onChange={handleChange}/>
          <input type="text"  placeholder='Departure time (e.g 8:30 AM)' value={formData.from_time} name='from_time' onChange={handleChange}/>
          <input type="text" placeholder='arrival time (e.g 5:00 PM)' value={formData.to_time} name='to_time' onChange={handleChange}/>
          <button type='submit'>{loading? 'saving...' :"Save"}</button>
        </form>
      </> :
       <>
        <div className="title-holder">
        <h3>All Tickets</h3>
        <button className='addTicket-btn' onClick={handleAddTicketBtn}>Add Ticket</button>
      </div>
      <div className='company-tickets-maincontainer'>
      <div className='clientManagement-history'>
<div className='clientManagement-history-searchbar'>
<input type="text" />
<img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
<div className='filterContainer'> 
<img className='filterContainer-img' src={filterIcon} alt="" />
  
</div>
</div>
<div className='clientManagement-history-labels'>
<p>Ticket ID</p>
<p>Passenger</p>
<p>Amount</p>
<p>Trip</p>
<p>Trip Date</p>

</div>
{tickets?.length > 0 ?
  <div className='clientManagement-list-holder' >
    {tickets.map((ticketz)=>(
          <>
          <div className='clientManagement-list-item' key={ticketz._id}>
          <p >...{ticketz._id.slice(-10)}</p>
          <p >Jane Doe</p>
          <p>#{ticketz.amount}</p>
          <div className='company-tickets-trip'>
            <p>{ticketz.from.slice(0,3)}</p> <img src={arrorRightRed} alt="" /><p>{ticketz.to.slice(0,3)}</p>
          </div>
          <p>04 Oct 2023</p>
            <div className='clientManagement-list-item-img2-container'>
            <img  className='clientManagement-list-item-img2' onClick={handleCompanyTicketdetails} src={navIcon} alt="nav Icon" />
            </div>
          </div>
          <div className="ticket-listItem" key={ticketz._id}>
            <div className="ticket-listItem1">
              <p>ticket ID:</p>
              <h4>...{ticketz._id.slice(-10)}</h4>
            </div>
          </div>
          </>
    ))}
    
  
  
  </div> :
  <div className='clientManagement-list-holder'><h3 style={{padding:'2rem'}}>No Tickets found</h3></div>
}

<div className='clientManagement-history-navigation'>
<div className='clientManagement-history-item'>
  <img src={doubleArrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2-active'>
  <p>1</p>
</div>
<div className='clientManagement-history-item2'>
  <p>2</p>
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowRight} alt="" />
</div>
<div className='clientManagement-history-item3'>
  <img src={doubleArrowRight} alt="" />
</div>

</div>

</div>
      </div>
      </>}
      
      
      
      </>:
      
      <>
      <div className='companyTicket-details-container'>
        <h3>Ticket Information</h3>
        <div className='print-btn-holder'><img src={printLogo} alt="" /><h5>Print</h5></div>
        <div className='company-ticket-details-mainContainer'>
        <div className="company-ticket-details-section1">
          <img src={gigmLogo} alt="" />
          <div>
            <h4>GUO Transport Company</h4>
            <p>GIGM286GD</p>
            </div>
        </div>
        <div className="company-ticket-details-section2">
          <div className='company-ticket-details-section2-items'>
            <div className='company-ticket-details-section2-item'><img src={ticketYellow} alt="" /> <h2>6:00 AM</h2> <p>ABJ</p></div>
              <div className='company-ticket-details-section2-item-img'> 
               <img src={arrorRightRed} alt="" />
              </div>
            <div className='company-ticket-details-section2-item'><p>LAG</p> <h2>9:30 PM</h2> <span>12 hrs 20 mins</span></div>
          </div>
          <p>20 April 2024</p>
        </div>
        <div className="company-ticket-details-section3">
          <div className='company-ticket-details-section3-main'>
            <div className="company-ticket-details-section3-main-top"><h5>Fare Summary</h5></div>
            <div className="company-ticket-details-section3-main-body">
              <div className='company-ticket-details-section3-main-body-item'>
                <label>Fare summary</label>
                <div className='company-ticket-details-section3-main-body-itemList'><p>Adult X 1</p> <span>#15,800</span>
              </div>
               </div>
            <div className='company-ticket-details-section3-main-body-item'>
                <div className='company-ticket-details-section3-main-body-itemList'><label>tax</label> <span>#200</span>
                </div>
              </div>
              <div className='company-ticket-details-section3-main-body-item'>
                <label >Total</label>
                <div className='company-ticket-details-section3-main-body-itemList'><label>1 traveler</label> <span className='mainSpan'>#16,000</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="company-ticket-details-section4">
          <img src={barCode} alt="" />
        </div>


      </div>
      </div>
       
      </>}
      
    </div>
  )
}

export default CompanyTickets