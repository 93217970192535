import React from "react";
import "./tranportcompanies.css"
import { IoMdSearch } from "react-icons/io";
import { MdFilterAlt } from "react-icons/md";
import companyLogo from"../../images/company1.png"
import { MdStarRate } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const TransportCompanies = ()=>{
    const transportCompanyList = useSelector((state)=>state.auth?.transportCompanies?.data || [])
    const navigate = useNavigate()

    const handleCompanyDetails = (transList) => {
  navigate('/transportcompanydetails', { state: { tranCompanyID: transList.tranCompanyID } });
};


    return (
        <div className="traveleerSide-ttransporcompanies-container">
            <div className="traveleerSide-ttransporcompanies-topsec">
                <h2>TRANSPORT COMPANIES</h2>
            </div>
            <div className="traveleerSide-ttransporcompanies-item1">
                <div className="traveleerSide-ttransporcompanies-item1a">
                     <IoMdSearch />
                    <input type="text" placeholder="search" />
                </div>
                <div className="traveleerSide-ttransporcompanies-item1a">
                     <MdFilterAlt />
                    <select name="" id="">
                        <option value="" >Filter</option>
                        <option value="">Time</option>
                        <option value="">Location</option>
                    </select>

                </div>
            </div>
            <div className="traveleerSide-ttransporcompanies-item-container">
            {transportCompanyList && transportCompanyList.length > 0 ? (
             transportCompanyList.map((transList) => (
                 <div key={transList.tranCompanyID} className="traveleerSide-ttransporcompanies-item-container-items">
                 <img src={transList.url} alt="Company Logo" />
                <div className="traveleerSide-ttransporcompanies-item-container-items1a">
                <div className="ratingHolder">
                     4.5 <MdStarRate />
                    </div>
        <h3>{transList.companyName || "Transport Company Name"}</h3>
        <p>Destinations: {transList.destinations || "N/A"}</p>
        <h6>Starting from # {transList.startingPrice || "0"}</h6>
        <div className="tansportCompanies-btnHolder">
        <button onClick={() => handleCompanyDetails(transList)}>View More</button>
        
        </div>
      </div>
      <div className="traveleerSide-ttransporcompanies-item-container-items1b">
        <div className="tansportCompanies-btnHolder2">
          4.3 <MdStarRate />
        </div>
        <button onClick={() => handleCompanyDetails(transList)}>View</button>
      </div>
    </div>
  ))
) : (
  <p>No transport company available now</p>
)}
  
            </div>
            <div className="travellerTicket-navigation">
          <div className="navBack">
          <MdOutlineKeyboardArrowLeft />
          </div>
          <div className="navPageNumber-holder">
            <p>1</p>
          </div>
          <div className="navForward">
          <MdOutlineKeyboardArrowRight />
          </div>
        </div>
        </div>
    )
}
export default TransportCompanies;