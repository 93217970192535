import React from 'react'
import "./HomePage.css"
import CompanyHeader from '../../components/CompanyHeader'
import CompanyFooter from '../../components/CompanyFooter'
import heroPAgeImage from "../../images/heroPageImage.png"
import Icon1 from "../../images/checkYellow.svg"
import { useNavigate } from 'react-router-dom'

const HomePage = () => {
const navigate = useNavigate()
  return (
    <div className='CompanyHomePage-main-Container'>
      <div className='companyHeader-Holder'>
        <CompanyHeader/>
      </div>
      <div className='company-heroPage'>
      <div className='company-heropage-leftSection'>
        <h1>List Your <br />
        <span>Transport Company</span> <br />
          on VadTrans.com </h1>
          <p>Register your company on VadTrans to reach travellers nationwide.</p>
      </div>
        <div className='company-heropage-rightSection'>
          <img src={heroPAgeImage} alt="" />
        </div>
      </div>
      <div className='comapny-homepage-content'>
        <div className='company-homepage-container'>
          <div className='company-homepage-container-section1'>
            <h2>Earn more with Visibility and <br />Constant bookings</h2>
          </div>
          <div className='company-homepage-container-section2'>
            <div className='company-homepage-container-section-items'>
                  <img src={Icon1} alt="" />
                  <p>Easy booking of trips</p>
            </div>
            <div className='company-homepage-container-section-items'>
                  <img src={Icon1} alt="" />
                  <p>Full control over your profile and finaces</p>
            </div>
            <div className='company-homepage-container-section-items'>
                  <img src={Icon1} alt="" />
                  <p>Registration is free and takes less than 15 minutes</p>
            </div>
            <button className='company-homepage-container-sectionBtn' onClick={()=> navigate('/companysignuppage1')}>Get started</button>
          </div>
          <div className='company-homepage-container-section3'>
            <div className=' company-homepage-container-section3-main'>
            <p>Already started your registration?</p>
            <span onClick={()=> navigate('/companysignuppage1')}>Continue your registration</span>
            </div>
          </div>
        </div>
      </div>
      <div className='companySide-footer'>
        <CompanyFooter/>
      </div>
    </div>
  )
}

export default HomePage
