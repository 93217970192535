import React from 'react'
import "./aboutTraveller.css"
import contactImg2 from "../../images/contactImg2.jpg"
import profilePics  from "../../images/profile.jpg"

const TravellerAboutUs = () => {
  return (
    <div className='traveller-aboutUs'>
      <div className="traveller-aboutUs-main">
        <div className="traveller-aboutUs-topSection">
          <h1>ABOUT US</h1>
        </div>
        <div className='traveller-aboutus-section2'>
              <div className="traveller-aboutus-section2-leftside">
                <p>Vadtrans was founded on the belief that transportation should be more than just a means of getting from point A to point B. We aim to create a seamless, enjoyable, and sustainable experience that transport people from one place to another.</p>
                <p>We are committed to providing an exceptional travel experience to our customers. <br />
                  We strive to create a great products and quality service that supersedes our customer’s taste. <br />
                  We embrace cutting-edge technology and innovative solutions.</p>
         </div>
              <div className="traveller-aboutus-section2-rightside">
                <img src={contactImg2} alt="" />
              </div>
        </div>
        <div className="traveller-aboutus-section3">
            <div className="traveller-aboutus-section3-leftside">
              <img src="/mission.png" alt="" />
              <div className='tav-abo-sec3-title'>
                <h3>Our Mission</h3>
                <div className='p-line-holder'>
                   <p className='p-line1'></p>
                   <p className='p-line2'></p>
                   <p className='p-line3'></p></div>
                </div>  
                <p className='abou-sec3-contentText'>To provide a satisfying transportation system and a loveable experience.</p>            

            </div>
            <div className="traveller-aboutus-section3-leftside">
              <img src="/vision.png" alt="" />
              <div className='tav-abo-sec3-title'>
                <h3>Our Vision</h3>
                <div className='p-line-holder'>
                   <p className='p-line1'></p>
                   <p className='p-line2'></p>
                   <p className='p-line3'></p></div>
                </div>  
                <p className='abou-sec3-contentText'>To build a reformed transportation system that sets the pace for the next generation.</p>            

            </div>
            
        </div>
        <div className="traveller-about-section4">
          <div className="traveller-about-section4-leftside">
                  <h5>Meet Our Team</h5>
                  <h3>Management Teams </h3>
          </div>
          <div className="traveller-about-section4-rightside">
            <div className="traveller-teamCard">
                <img src="/victor.jpg" alt="img" />
                <div className='traveller-team'>
                  <h3>Victor Adesope</h3>
                  <h5>Business development manager</h5>
                </div>
            </div>
            <div className="traveller-teamCard">
                <img src='/idowu.jpg' alt="img" />
                <div className='traveller-team'>
                  <h3>Ifeoluwa Idowu</h3>
                  <h5>Product manager</h5>
                </div>
            </div>
            <div className="traveller-teamCard">
                <img src={profilePics} alt="" />
                <div className='traveller-team'>
                  <h3>Ujah Collins</h3>
                  <h5>Software Engineer</h5>
                </div>
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default TravellerAboutUs
