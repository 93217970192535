import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "./headerTraveller.css";
import phoneRed from "../images/phoneRed.svg";
import envelope from "../images/envelope.svg";
import logoA from "../images/logo.svg";
import searchIcon from "../images/search.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import menuBar from "../../TravellerSide/images/MenuBar.svg";
import notFyIcon from "../images/notFyIcon.svg";
import user4 from "../../Company/images/user2.svg";
import { FaAngleDown } from "react-icons/fa6";
import { fetchUserData, logout } from '../../../actions/authActions';
import { MdOutlineNotificationsNone } from "react-icons/md";

const HeaderTraveller = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [userProfileOption, setUserProfileOption] = useState(false);
    const [menuIsTrue, setMenuIsTrue] = useState(false);
    const myNotifications = useSelector((state)=>state.auth?.notifications?.data || [])
    const userData = useSelector((state) => state.auth.userData);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        if (isAuthenticated && userId && token) {
            dispatch(fetchUserData(userId, token, 'traveler'));
        }
    }, [dispatch, ]);


    const handleListItemBtn = () => {
        navigate("/companyside");
        setMenuIsTrue(false);
    };

    const handleProfileBtn = () => {
        navigate('/userprofile');
        setMenuIsTrue(false);
    };
    
    const handleUserProfileOptionBtn = () => {
        setUserProfileOption(!userProfileOption);
    };
    const handleUserProfileOptionBtn2 = () => {
        navigate('/editprofile')
        // setUserProfileOption(!userProfileOption);
        setMenuIsTrue(false)
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };
    const isActive = (path) => location.pathname === path ? 'activeP' : '';
    return (
        <div className='header-traveller-main'>
            <div className='Traveller-header-top'>
                <div className='Traveller-header-top-item'>
                    <img src={phoneRed} alt="phone" />
                    <p>+234-812-945-0899</p>
                </div>
                <div className='Traveller-header-top-item'>
                    <img src={envelope} alt="envelope" />
                    <p>contact@vadtrans.com</p>
                </div>
                <div className="traveller-button-holder">
                    <button>Follow Us</button>
                </div>
            </div>
            <div className='traveller-header-bottom'>
                <div className="traveller-header-bottom-item1">
                    <img style={{cursor:'pointer'}} src={logoA} alt="logo"  onClick={()=> navigate('/') }/>
                </div>
                <div className="traveller-header-bottom-item2">
                    <p className={isActive('/')} onClick={() => navigate("/")}>Home</p>
                    <p className={isActive('/aboutus')} onClick={() => navigate('/aboutus')}>About Us</p>
                    <p className={isActive('/transportTracking')} onClick={() => navigate("/transportTracking")}>Tracking</p>
                    <p className={isActive('/contactus')} onClick={() => navigate('/contactus')}>Contact Us</p>
                
                </div>
                {isAuthenticated ? (
                    <div className="traveller-header-bottom-item3">
                        <div className="travelle-header-sec3Items">
                            <img src={notFyIcon} alt="notification" onClick={() => navigate('/mynotifications')} />
                        </div>
                        <div className="travelle-header-sec3Items2">
                            <img className="travelle-header-sec3Items2-img" src={userData? userData?.data?.profilePicture?.url : user4} alt="user" />
                            <p onClick={handleProfileBtn}>{userData?.data.
                            
                            firstName || 'User'}</p>
                            <FaAngleDown onClick={handleUserProfileOptionBtn} />
                        </div>
                        <div className="travelle-header-sec3Items">
                            <img src={searchIcon} alt="search" />
                        </div>
                    </div>
                ) : (
                    <div className="traveller-header-bottom-item3">
                        <button className="traveller-header-1" onClick={handleListItemBtn}>List your Company</button>
                        <button className="traveller-header-button-2" onClick={() => navigate("/login")}>Login</button>
                        <button className="traveller-header-button-3" onClick={() => navigate("/signup")}>Signup</button>
                        <img src={searchIcon} alt="search" />
                    </div>
                )}
                <div className="traveller-header-bottom-item4">
                    <img src={menuBar} alt="menu" onClick={() => setMenuIsTrue(!menuIsTrue)} />
                </div>
            </div>
            {
                userProfileOption&& (
                    <div className='userProfile-option-holder'>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p className='menuItems-lists-Holder-btmSec-item2-p' onClick={()=>{handleProfileBtn(); setUserProfileOption(false);}}>Profile</p>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p className='menuItems-lists-Holder-btmSec-item2-p' onClick={() => {navigate('/editprofile'); setUserProfileOption(false);}}>Edit profile</p>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p className='menuItems-lists-Holder-btmSec-item2-p' onClick={() => { handleLogout(); setUserProfileOption(false); }}>Logout</p>
                                </div>
                            </div>
                )
            }
            {menuIsTrue && (
                <div className='menuItems-lists-Holder'>
                    <div className="menuItems-lists-Holder-topSec">
                        <div className='menuItems-lists-Holder-topSec-item'>
                            <img src={phoneRed} alt="phone" />
                            <p>+234-812-945-0899</p>
                        </div>
                        <div className='menuItems-lists-Holder-topSec-item'>
                            <img src={envelope} alt="envelope" />
                            <p>contact@vadtrans.com.ng</p>
                        </div>
                        <div className="menuItems-lists-Holder-topSec-item-button-holder">
                            <button>Follow Us</button>
                        </div>
                    </div>
                    <div className="menuItems-lists-Holder-btmSec">
                        <div className="menuItems-lists-Holder-btmSec-item1">
                            <div className="menuItems-lists-Holder-btmSec-srchBtn">
                                <img src={searchIcon} alt="search" /><input type="text" placeholder='search' />
                            </div>
                        </div>
                        <div className="menuItems-lists-Holder-btmSec-item2-active">
                            <p onClick={() => {navigate("/"); setMenuIsTrue(false)}}>Home</p>
                        </div>
                        <div className="menuItems-lists-Holder-btmSec-item2">
                            <p onClick={() => {navigate('/aboutus'); setMenuIsTrue(false)}}>About us</p>
                        </div>
                        <div className="menuItems-lists-Holder-btmSec-item2">
                            <p onClick={() => {navigate("/transportTracking"); setMenuIsTrue(false)}}>Tracking</p>
                        </div>
                        <div className="menuItems-lists-Holder-btmSec-item2">
                            <p onClick={() => {navigate('/contactus'); setMenuIsTrue(false)}}>Contact us</p>
                        </div>
                        {userData ? (
                            <>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p onClick={handleProfileBtn}>Profile</p>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p onClick={handleUserProfileOptionBtn2}>Edit profile</p>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p onClick={handleLogout}>Logout</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="menuItems-lists-Holder-btmSec-item3">
                                    <button onClick={handleListItemBtn}>List your Company</button>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item3a">
                                    <button style={{backgroundColor:'black', color:'white'}} onClick={() =>{ navigate("/login"); setMenuIsTrue(false)}}>Login</button>
                                    <button style={{backgroundColor:'red', color:'white'}} onClick={() => {navigate("/signup"); setMenuIsTrue(false)}}>Signup</button>
                                </div>
                                
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default HeaderTraveller;
