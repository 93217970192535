import React, { useState, useEffect } from 'react';
import "./login.css";
import loginImage from "../../../images/panaLoginMain.svg";
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessages, fetchUserData, login } from '../../../../../actions/authActions';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { IoMdEye } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa6";


const LoginTraveller = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { token, error, message } = useSelector((state) => state.auth);
  const userId = localStorage.getItem('userId')
  const userData = useSelector((state) => state.auth.userData);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    if (!formData.email) {
      Swal.fire('Error', 'Email is required', 'error');
      return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      Swal.fire('Error', 'Enter a valid email', 'error');
      return false;
    }
    if (!formData.password) {
      Swal.fire('Error', 'Password is required', 'error');
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      dispatch(login(formData, 'traveler'))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (userId && token) {
      dispatch(fetchUserData(userId, token, 'traveler'));
    }
  
    if (token && message) {
      Swal.fire('Success', message || 'Login Successful', 'success').then(() => {
        dispatch(clearMessages()); // Clear message after displaying
        navigate('/'); // Navigate after clearing the message
      });
    } else if (error) {
      Swal.fire('Error', error || 'An error occurred').then(() => {
        dispatch(clearMessages()); // Clear error after displaying
      });
    }
  }, [token, userId, message, error, dispatch, navigate]);
  
  useEffect(() => {
    if (userId && token) {
      dispatch(fetchUserData(userId, token, 'traveler'));
    }
  }, [token, userId, dispatch]);
  
  return (
    <div className='traveller-login-container'>
      <div className="traveller-login-main">
        <div className='traveller-login-mainContainer'>
          <form className="Traveller-login-main-leftSide" onSubmit={handleSubmit}>
            <h2>Hello! Welcome Back</h2>
            <div className="traveler-login-leftside-item">
              <label>Email</label>
              <input className='password-inputHolder2-input' type="email" name="email" value={formData.email} onChange={handleChange} placeholder='Email' />
            </div>
            <div className="traveler-login-leftside-item">
              <label>Password</label>
              <div className="passContain">
              <input className='password-inputHolder3' type={visible ? "text" : 'password'} name="password" value={formData.password} onChange={handleChange} placeholder='Password' />
              {visible ? < FaEyeSlash  onClick={()=>setVisible(false)} /> : < IoMdEye   onClick={()=>setVisible(true)}/>}
              </div>
              
            </div>
            <div className='traveller-login-selectLine'>
              <div>
                <input type="checkbox" placeholder='remember me' /><label>Remember me</label>
              </div>
              <span><NavLink to={"/passwordreset"}>Forgot password?</NavLink></span>
            </div>
            <div className='traveller-login-btnHolder'>
              <button type='submit' disabled={loading}>
                {loading ? 'Logging in...' : 'Login'}
              </button>
            </div>
            <p className='p-tag-login'>Don't have an account? <span onClick={() => navigate('/signup')}>sign up</span></p>
          </form>
          <div className="Traveller-login-main-RightSide">
            <img src={loginImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginTraveller;
