import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { IoArrowBack } from "react-icons/io5";
import driverPics from "../../images/driver.png"
import { selectSeat } from '../../../../actions/authActions'


const SelectSeat = () => {
  const bookedTicketId = useSelector((state)=>state.auth.bookedTicket?.ticket?._id || '')
  const navigate = useNavigate()
  const token = useSelector((state)=>state.auth?.token)
  const dispatch=useDispatch();

    const [formData, setFormData] = useState({
        seatNumber:0,
        status:''
      })
    const [seatDatas, setSeatDatas] = useState([
        { id: 1, seatNumber: 1, status: "available" },
        { id: 2, seatNumber: 2, status: "available" },
        { id: 3, seatNumber: 3, status: "reserved" },
        { id: 4, seatNumber: 4, status: "available" },
        { id: 5, seatNumber: 5, status: "available" },
        { id: 6, seatNumber: 6, status: "available" },
        { id: 7, seatNumber: 7, status: "selected" },
        { id: 8, seatNumber: 8, status: "available" },
        { id: 9, seatNumber: 9, status: "available" },
        { id: 10, seatNumber: 10, status: "available" },
        { id: 11, seatNumber: 11, status: "selected" },
        { id: 12, seatNumber: 12, status: "available" },
        { id: 13, seatNumber: 13, status: "available" },
        { id: 14, seatNumber: 14, status: "available" },
        { id: 15, seatNumber: 15, status: "reserved" },
        { id: 16, seatNumber: 16, status: "available" },
        { id: 17, seatNumber: 17, status: "selected" },
      ])
    const filteredSeatData1 = seatDatas.filter((seatData)=>seatData.id > 0 &&seatData.id < 9)
    const filteredSeatData2 = seatDatas.filter((seatData)=>seatData.id > 8 &&seatData.id < 18)
    const handleBack = () => {
      navigate(-1);
    };
    const handleSeatClick = (seatId) => {
      setSeatDatas((prevSeats) =>
        prevSeats.map((seat) => {
          if (seat.id === seatId) {
            // Update formData when a seat is selected
            setFormData({
              seatNumber: seat.seatNumber,
              status: "selected",
            });
            return { ...seat, status: "selected" }; 
            
          }
          return seat.status === "selected"
            ? { ...seat, status: "available" } 
            : seat; 
        })
      );
    };
    
     
    const handleSelectSeat = async () => {
  if (formData && bookedTicketId) {
    try {
      // Dispatch the action and wait for its result
      const response = await dispatch(selectSeat(formData, bookedTicketId, token));

      // Check if the response payload indicates success
      if (response?.payload?.message === "Seat successfully selected!") {
        Swal.fire({
          icon: "success",
          title: "Seat Selected",
          text: response.payload.message || "Seat selection was successful!",
        });
        navigate("/bookings");
      } else {
        // Handle failure response
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response?.payload?.message || "Failed to select seat. Please try again.",
        });
      }
    } catch (error) {
      // Handle unexpected errors
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "An unexpected error occurred.",
      });
    }
  } else {
    // Handle missing data
    Swal.fire({
      icon: "warning",
      title: "Missing Data",
      text: "Please ensure all required information is filled.",
    });
  }
};


    // const handleSelectSeat =()=>{
    //     if(formData && bookedTicketId){
    //       dispatch(selectSeat(formData, bookedTicketId,  token))
    //     }
    //    navigate('/addluggage')
    //   }


  return (
    <div className='travellerSide-homepage-container'>
            <div className='userSearch-details-holder'>

<div className="userSearch-details-item1">
<IoArrowBack size={25}  onClick={handleBack}/>
<h3>Select seats</h3>
</div>
<div className="userSearch-details-item2">
  <div className="userSearch-details-item2a">
    <div className="userSearch-details-item2a1">
      <div className="userSearch-details-item2a1a"></div> <h5>Reserved</h5>
    </div>
    <div className="userSearch-details-item2a1">
      <div className="userSearch-details-item2a1b"></div> <h5>Available</h5>
    </div>
    <div className="userSearch-details-item2a1">
      <div className="userSearch-details-item2a1c"></div> <h5>Selected</h5>
    </div>
  </div>
  <div className="userSearch-details-item2b">
      <h5>Selected Seat: Seat no 3</h5>
  </div>
  <div className="userSearch-details-item2c">
    <div className="userSearch-details-item2c1">
      <div className="userSearch-details-item2c1a">
        <img src={driverPics} alt="" /><p>driver</p>
      </div>

      {filteredSeatData1.map((seatdata1)=>(
          <div className= {seatdata1.status=== 'available'? "userSearch-details-item2c1b" : seatdata1.status === 'selected'? 'userSearch-details-item2c1d' : seatdata1.status === 'reserved'? 'userSearch-details-item2c1c': ''} key={seatdata1.id} onClick={() => handleSeatClick(seatdata1.id)}>
          {seatdata1.seatNumber}
        </div>
      ))}
    </div>
    <div className="userSearch-details-item2c1">
      {filteredSeatData2.map((seatdata2)=>(
          <div className= {seatdata2.status=== 'available'? "userSearch-details-item2c1b" : seatdata2.status === 'selected'? 'userSearch-details-item2c1d' : seatdata2.status === 'reserved'? 'userSearch-details-item2c1c': ''} key={seatdata2.id} onClick={() => handleSeatClick(seatdata2.id)}>
          {seatdata2.seatNumber}
        </div>

      ))}
      
    </div>
  </div>
</div>
<div className="userSearch-details-item3">
    <button onClick={handleSelectSeat}>Next</button>
</div>
</div>
    </div>
  )
}

export default SelectSeat